import jwt_decode from "jwt-decode";

function saveToken(access_token, refreshToken) {
  localStorage.setItem("token", access_token);
  localStorage.setItem("refresh_token", refreshToken);
}

function getToken() {
  try {
    return jwt_decode(localStorage.getItem("token"));
  } catch (error) {}
}

function destroyToken() {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
}

export { saveToken, getToken, destroyToken };
