import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";

import { interfaceSecureUrl } from "../utils/axiosInterfaceBaseUrl";
import { searchOpenUrl } from "../utils/axiosAuthBaseUrl";

const initialState = {
  loading: false,
  cabinetLoading: false,
  comforts: null,
  statuses: null,
  classes: null,
  photos: null,
  adverts: null,
  rooms: null,
  totalItems: null,
  myObjects: null,
  selfInfo: null,
  advertQueries: {
    offset: 0,
    limit: 12,
  },
  popularAdverts: null,
  premiumObj: null,
  topObj: null,
  singleAdvert: null,
  singleDeveloperItems: null,
  isFullDev: null,
  logo: "",
  dev_status: null,
};

export const getComforts = createAsyncThunk(
  "novostroyki/getComforts",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `new_building/get_building_amenities`;

      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getClasses = createAsyncThunk(
  "novostroyki/getClasses",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `new_building/get_building_class`;

      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAppartmentRooms = createAsyncThunk(
  "novostroyki/getAppartmentRooms",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `new_building/get_apartment_rooms`;

      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getStatuses = createAsyncThunk(
  "novostroyki/getStatuses",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `new_building/get_building_status`;

      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addDeveloper = createAsyncThunk(
  "novostroyki/addDeveloper",
  async ({ logo, body, navigate }, { rejectWithValue, dispatch }) => {
    try {
      const uri = `dashboard/new_building/developers`;

      const logoResponse = await interfaceSecureUrl.post(
        `dashboard/new_building/developers/logo`,
        logo,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      const obj = {
        ...body,
        logo: logoResponse.data.data,
      };

      const { data } = await interfaceSecureUrl.post(uri, obj);

      if (navigate) {
        navigate("/");
      }
      dispatch(checkDeveloper());

      return data;
    } catch (error) {
      if (
        error.response.data.data ===
        'ERROR: duplicate key value violates unique constraint "developers_pkey" (SQLSTATE 23505)'
      ) {
        message.info("Ваш профиль уже на модерации");
      }
      return rejectWithValue(error);
    }
  }
);

export const updateDeveloper = createAsyncThunk(
  "novostroyki/updateDeveloper",
  async ({ logo, body }, { rejectWithValue, dispatch }) => {
    try {
      const uri = "dashboard/new_building/developers/update_profile";

      let logoResponse;

      if (logo) {
        logoResponse = await interfaceSecureUrl.post(
          "dashboard/new_building/developers/logo",
          logo,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
      }

      const obj = { ...body };

      if (logoResponse) {
        obj.logo = logoResponse.data.data;
      }

      const { data } = await interfaceSecureUrl.put(uri, obj);

      dispatch(getMyObjects());
      message.success("Профил обновлен");

      return data;
    } catch (error) {
      if (
        error.response.data.data ===
        "no multipart boundary param in Content-Type"
      ) {
        message.error("Выберите логотип!");
      }
      return rejectWithValue(error);
    }
  }
);

export const addObject = createAsyncThunk(
  "novostroyki/addObject",
  async ({ logo, photos, body, objItems, navigate }, { rejectWithValue }) => {
    try {
      let uri = `dashboard/new_building/developers/objects`;

      const logoResponse = await interfaceSecureUrl.post(
        `dashboard/new_building/objects/logo`,
        logo,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      const photosResponse = await interfaceSecureUrl.post(
        `dashboard/new_building/objects/object_photos`,
        photos,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      let obj = {
        ...body,
        logo: logoResponse.data.data,
        photos: photosResponse.data.filenames,
      };

      const { data } = await interfaceSecureUrl.post(uri, obj);

      const formData = new FormData();
      objItems.forEach((elem) => {
        formData.append(`upload`, elem.layout);
      });

      const roomsPhotosResponse = await interfaceSecureUrl.post(
        `dashboard/new_building/objects/layout`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      let items = objItems.map((elem, index) => ({
        ...elem,
        layout: roomsPhotosResponse.data.filenames[index],
        object_id: data.data.id,
      }));

      const addObjectItemsResponse = await interfaceSecureUrl.post(
        `dashboard/new_building/apartments`,
        { appartments: items }
      );

      if (navigate) {
        navigate("/addAdvertFinish");
      }

      return addObjectItemsResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateObject = createAsyncThunk(
  "interfaceApi/updateAdvert",
  async (
    { logo, isFormLogo, body, objItems, itemsWithId, navigate },
    { rejectWithValue }
  ) => {
    try {
      const uri = `dashboard/new_building/objects`;

      const logoResponse =
        isFormLogo &&
        (await interfaceSecureUrl.post(
          `dashboard/new_building/objects/logo`,
          logo,
          { headers: { "Content-Type": "multipart/form-data" } }
        ));

      const obj = {
        ...body,
        logo: isFormLogo ? logoResponse.data.data : logo,
      };

      // eslint-disable-next-line no-unused-vars
      const { data } = await interfaceSecureUrl.put(uri, obj);

      const formData = new FormData();

      objItems.forEach((elem) => {
        formData.append(`upload`, elem.layout);
      });

      const roomsPhotosResponse = await interfaceSecureUrl.post(
        `dashboard/new_building/objects/layout`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      let fileItemsLength = 0;

      const items = itemsWithId.map((elem, index) => {
        const { key, local, ...withoutKey } = objItems[index];
        return {
          id: elem.id,
          object_id: elem.object_id,
          ...withoutKey,
          layout: objItems[index]?.layout?.includes?.("https")
            ? objItems[index].layout.split("https://crm.xona.tj")[
                objItems[index].layout.split("https://crm.xona.tj").length - 1
              ]
            : roomsPhotosResponse.data.filenames?.[fileItemsLength++] ||
              objItems[index].layout.split("https://crm.xona.tj")[
                objItems[index].layout.split("https://crm.xona.tj").length - 1
              ],
        };
      });

      const addObjectItemsResponse = await interfaceSecureUrl.put(
        `dashboard/new_building/apartments`,
        items
      );

      if (navigate) {
        navigate("/cabinet/my-objects");
      }

      message.success("Обновлён");

      return addObjectItemsResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAdverts = createAsyncThunk(
  "novostroyki/getAdverts",
  async (queries, { rejectWithValue }) => {
    let uri = `new_building/announcements?offset=${queries.offset}&limit=${
      queries.limit
    }&region=${queries.region || "1"}&tarif=free`;

    if (queries.name) {
      uri += `&name=${queries.name}`;
    }

    if (queries.status) {
      uri += `&status=${queries.status}`;
    }
    if (queries.min_price) {
      uri += `&min_price=${queries.min_price}`;
    }

    if (queries.max_price) {
      uri += `&max_price=${queries.max_price}`;
    }

    try {
      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPopularAdverts = createAsyncThunk(
  "novostroyki/getPopularAdverts",
  async ({ page, limit, region }, { rejectWithValue }) => {
    try {
      let uri = `new_building/announcements?page=${page}&limit=${limit}&region=${
        region || 1
      }&tarif=free`;

      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPremiumObj = createAsyncThunk(
  "novostroyki/getPremiumObj",
  async ({ page, limit, region }, { rejectWithValue }) => {
    try {
      let uri = `new_building/announcements?page=${page}&limit=${limit}&region=${
        region || 1
      }&tarif=premium`;

      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTopObj = createAsyncThunk(
  "novostroyki/getTopObj",
  async ({ page, limit, region }, { rejectWithValue }) => {
    try {
      let uri = `new_building/announcements?page=${page}&limit=${limit}&region=${
        region || 1
      }&tarif=top`;

      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSingleItem = createAsyncThunk(
  "novostroyki/getSingleItem",
  async (id, { rejectWithValue }) => {
    try {
      let uri = `new_building/objects/${id}`;

      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSingleDeveloperItems = createAsyncThunk(
  "novostroyki/getSingleDeveloperItems",
  async (id, { rejectWithValue }) => {
    try {
      let uri = `new_building/developers/objects/${id}`;
      const { data } = await searchOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkDeveloper = createAsyncThunk(
  "novostroyki/checkDeveloper",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `dashboard/new_building/developers/check_dev`;

      const { data } = await interfaceSecureUrl.get(uri);
      return data;
    } catch (error) {
      if (error.response.data.data !== "Доступ запрещен") {
        return rejectWithValue(error);
      }
    }
  }
);

export const getMyObjects = createAsyncThunk(
  "novostroyki/getMyObjects",
  async (_, { rejectWithValue }) => {
    try {
      const uri = `dashboard/new_building/developers/my_objects`;

      const { data } = await interfaceSecureUrl.get(uri);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const unpublishObject = createAsyncThunk(
  "interfaceApi/unpublishObj",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const uri = `dashboard/new_building/update_status?id=${id}&status=false`;
      const { data } = await interfaceSecureUrl.post(uri);
      dispatch(getMyObjects());
      message.success("Снято с публикации");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const publishObject = createAsyncThunk(
  "interfaceApi/publishObj",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const uri = `dashboard/new_building/update_status?id=${id}&status=true`;
      const { data } = await interfaceSecureUrl.post(uri);
      dispatch(getMyObjects());
      message.success("Опубликован");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteObject = createAsyncThunk(
  "interfaceApi/deleteObject",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      let uri = `dashboard/new_building/objects/?id=${id}`;

      const { data } = await interfaceSecureUrl.delete(uri);

      dispatch(getMyObjects());

      message.success("Удалён");

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteObjImage = createAsyncThunk(
  "interfaceApi/deleteObjImage",
  async ({ id, url }, { rejectWithValue, dispatch }) => {
    try {
      const uri = `dashboard/new_building/objects/object_photos`;

      const { data } = await interfaceSecureUrl.delete(uri, {
        params: {
          id,
          path: url,
        },
      });

      message.success("Удалeн");

      dispatch(getSingleItem(id));

      return data;
    } catch (error) {
      message.error("Ошибка при удалении");
      return rejectWithValue(error);
    }
  }
);

export const uploadObjImages = createAsyncThunk(
  "interfaceApi/uploadObjImages",
  async ({ id, images }, { rejectWithValue, dispatch }) => {
    try {
      const photosResponse = await interfaceSecureUrl.post(
        `dashboard/new_building/objects/object_photos`,
        images,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      const obj = {
        photos: photosResponse.data.filenames,
        object_id: +id,
      };

      // eslint-disable-next-line no-unused-vars
      const { data } = await interfaceSecureUrl.post(
        `dashboard/new_building/objects/upload_new`,
        obj
      );

      message.success("Загружено");

      dispatch(getSingleItem(id));

      return data;
    } catch (error) {
      message.error("Ошибка при загрузке");
      return rejectWithValue(error);
    }
  }
);

export const uploadRoomImage = createAsyncThunk(
  "interfaceApi/uploadRoomImage",
  async ({ objId, id, image }, { rejectWithValue, dispatch }) => {
    try {
      const uri = `${id}`;

      const { data } = await interfaceSecureUrl.post(uri, image);

      message.success("Загружено");
      dispatch(getSingleItem(objId));

      return data;
    } catch (error) {
      message.error("Ошибка при загрузке");
      return rejectWithValue(error);
    }
  }
);

export const deleteObjRoom = createAsyncThunk(
  "interfaceApi/deleteObjRoom",
  /**
   * @param { { id: number; object_id: number } } props
   */
  async ({ id, object_id }, { rejectWithValue, dispatch }) => {
    try {
      const uri = `dashboard/new_building/apartments?id=${id}`;

      const { data } = await interfaceSecureUrl.delete(uri);

      dispatch(getSingleItem(object_id));

      message.success("Удалён");

      return data;
    } catch (error) {
      message.warning("Ошибка при удалении");

      return rejectWithValue(error);
    }
  }
);

export const uploadObjRoom = createAsyncThunk(
  "interfaceApi/uploadObjRoom",
  async ({ body, object_id }, { rejectWithValue, dispatch }) => {
    try {
      const uri = `dashboard/new_building/apartments`;

      const formData = new FormData();

      body.forEach((i) => {
        formData.append(`upload`, i.layout);
      });

      const roomsPhotosResponse = await interfaceSecureUrl.post(
        `dashboard/new_building/objects/layout`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      const objArrays = body.map((i, index) => {
        return {
          rooms: i.rooms,
          area: i.area,
          floor: i.floor,
          block: i.block,
          price: i.price,
          layout: roomsPhotosResponse.data.filenames[index],
          object_id,
        };
      });

      const { data } = await interfaceSecureUrl.post(uri, {
        appartments: objArrays,
      });

      dispatch(getSingleItem(object_id));

      message.success("Сохранено");

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const novostroykiSlice = createSlice({
  name: "novostroykiState",
  initialState,
  reducers: {
    setAdvertsQueries: (state, action) => {
      const { payload } = action;
      state.advertQueries = { ...payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getComforts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getComforts.fulfilled, (state, action) => {
      state.loading = false;
      state.comforts = action.payload.data;
    });
    builder.addCase(getComforts.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getClasses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getClasses.fulfilled, (state, action) => {
      state.loading = false;
      state.classes = action.payload.data;
    });
    builder.addCase(getClasses.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getStatuses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStatuses.fulfilled, (state, action) => {
      state.loading = false;
      state.statuses = action.payload.data;
    });
    builder.addCase(getStatuses.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addObject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addObject.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addObject.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAdverts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdverts.fulfilled, (state, action) => {
      state.loading = false;
      state.adverts = action.payload.data;
      state.totalItems = action.payload.data.total_records;
    });
    builder.addCase(getAdverts.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getPopularAdverts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPopularAdverts.fulfilled, (state, action) => {
      state.loading = false;
      state.popularAdverts = action.payload.data;
    });
    builder.addCase(getPopularAdverts.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getPremiumObj.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPremiumObj.fulfilled, (state, action) => {
      state.loading = false;
      state.premiumObj = action.payload.data;
    });
    builder.addCase(getPremiumObj.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTopObj.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTopObj.fulfilled, (state, action) => {
      state.loading = false;
      state.topObj = action.payload.data;
    });
    builder.addCase(getTopObj.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getSingleItem.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSingleItem.fulfilled, (state, action) => {
      state.loading = false;
      state.singleAdvert = action.payload.data;
    });
    builder.addCase(getSingleItem.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getSingleDeveloperItems.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSingleDeveloperItems.fulfilled, (state, action) => {
      state.loading = false;
      state.singleDeveloperItems = action.payload.data.developer_info;
    });
    builder.addCase(getSingleDeveloperItems.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(checkDeveloper.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(checkDeveloper.fulfilled, (state, action) => {
      state.loading = false;
      state.isFullDev = action.payload?.data;
    });
    builder.addCase(checkDeveloper.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addDeveloper.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addDeveloper.fulfilled, (state) => {
      state.loading = false;
      notification["success"]({
        description: "Успешно добавлено!",
      });
    });
    builder.addCase(addDeveloper.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getMyObjects.pending, (state) => {
      state.cabinetLoading = true;
      state.dev_status = null;
    });
    builder.addCase(getMyObjects.fulfilled, (state, action) => {
      const { Objects, ...rest } = action.payload.data.developer_info;
      state.cabinetLoading = false;
      state.myObjects = Objects;
      state.selfInfo = rest;
      state.dev_status = true;
    });
    builder.addCase(getMyObjects.rejected, (state) => {
      state.cabinetLoading = false;
      state.isFullDev = false;
      state.dev_status = false;
    });

    builder.addCase(deleteObject.pending, (state) => {
      state.cabinetLoading = true;
    });
    builder.addCase(deleteObject.fulfilled, (state) => {
      state.cabinetLoading = false;
    });
    builder.addCase(deleteObject.rejected, (state) => {
      state.cabinetLoading = false;
    });

    builder.addCase(getAppartmentRooms.pending, (state) => {
      state.cabinetLoading = true;
    });
    builder.addCase(getAppartmentRooms.fulfilled, (state, action) => {
      state.cabinetLoading = false;
      state.rooms = action.payload.data;
    });
    builder.addCase(getAppartmentRooms.rejected, (state) => {
      state.cabinetLoading = false;
    });
  },
});

export const { setAdvertsQueries } = novostroykiSlice.actions;

export default novostroykiSlice.reducer;
