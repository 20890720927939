import { combineReducers } from "@reduxjs/toolkit";
import { routerReducer } from "react-router-redux";

import novostroykiReducer from "./Novostroyki";
import interfaceReducer from "./Interface";
import authReducer from "./Auth";

const rootReducer = combineReducers({
  router: routerReducer,
  auth: authReducer,
  interfaceState: interfaceReducer,
  novostroykiState: novostroykiReducer,
});

export default rootReducer;
