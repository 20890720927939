import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

import { authOpenUrl, authSecureUrl } from "../utils/axiosAuthBaseUrl";
import { saveToken, destroyToken } from "../utils/Token";

const initialState = {
  status: "",
  loading: false,
  authModalVisible: false,
  authModalSteps: 0,
  phoneNumber: "",
  numberForForgotPass: "",
  userInfo: null,
  lastModalText: "",
};

export const registration = createAsyncThunk(
  "authentication/registration",
  async (body, { rejectWithValue }) => {
    try {
      let uri = "login";
      const { data } = await authOpenUrl.post(uri, body);
      return data;
    } catch (error) {
      if (error.response.status === 400) {
        message.info("Пользовател уже сушествует!");
      }
      return rejectWithValue(error);
    }
  }
);

export const confirm = createAsyncThunk(
  "authentication/confirm",
  async (body, { rejectWithValue }) => {
    try {
      let uri = `login/${body.phone}/${body.code}`;
      const { data } = await authOpenUrl.get(uri);
      saveToken(data?.data?.accessToken, data?.data?.refreshToken);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resend = createAsyncThunk(
  "authentication/resend",
  async (body, { rejectWithValue }) => {
    try {
      let uri = `user/resend/${body}`;
      const { data } = await authOpenUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const login = createAsyncThunk(
  "authentication/login",
  async (body, { rejectWithValue }) => {
    try {
      let uri = "login";
      const { data } = await authOpenUrl.post(uri, body);
      return data;
    } catch (error) {
      if (error.response.data.data === "user not found") {
        message.warning("Пользователь не найден");
      }
      return rejectWithValue(error);
    }
  }
);

export const refreshToken = createAsyncThunk(
  "authentication/refreshToken",
  async (token, { rejectWithValue }) => {
    try {
      let uri = `auth/refresh?refreshToken=${token}`;
      const { data } = await authOpenUrl.get(uri);
      localStorage.setItem("token", data.accessToken);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "authentication/getUserInfo",
  async (_, { rejectWithValue }) => {
    try {
      let uri = "user/current";
      const { data } = await authSecureUrl.get(uri);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  "authentication/logout",
  async (navigate, { rejectWithValue }) => {
    try {
      let uri = "auth/logout";
      const { data } = await authSecureUrl.get(uri);
      destroyToken();
      navigate("/");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAvatar = createAsyncThunk(
  "interfaceApi/deleteAvatar",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const url = `/developer/logo`;

      const { data } = await authSecureUrl.delete(url);

      return data;
    } catch (error) {
      rejectWithValue(error);
    } finally {
      dispatch(getUserInfo());
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setModalVisible: (state, action) => {
      state.authModalVisible = action.payload;
    },
    setModalStep: (state, action) => {
      state.authModalSteps = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setPhoneNumberForForgotPass: (state, action) => {
      state.numberForForgotPass = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registration.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(registration.fulfilled, (state) => {
      state.loading = false;
      state.status = "success";
      state.authModalSteps = 2;
    });
    builder.addCase(registration.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });
    builder.addCase(confirm.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(confirm.fulfilled, (state) => {
      state.status = "success";
      state.loading = false;
      state.authModalSteps += 1;
      state.lastModalText = "Вы успешно зарегистрировались!";
    });
    builder.addCase(confirm.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });
    builder.addCase(resend.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(resend.fulfilled, (state) => {
      state.status = "success";
      state.loading = false;
    });
    builder.addCase(resend.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });
    builder.addCase(login.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state) => {
      state.status = "success";
      state.loading = false;
      state.authModalSteps = 2;
    });
    builder.addCase(login.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });
    builder.addCase(getUserInfo.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.status = "success";
      state.loading = false;
      state.userInfo = action.payload.data;
    });
    builder.addCase(getUserInfo.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });

    builder.addCase(logout.pending, (state) => {
      state.status = "pending";
      state.loading = true;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.status = "success";
      state.loading = false;
      state.userInfo = null;
    });
    builder.addCase(logout.rejected, (state) => {
      state.status = "rejected";
      state.loading = false;
    });

    builder.addCase(deleteAvatar.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAvatar.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAvatar.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setModalVisible,
  setModalStep,
  setPhoneNumber,
  setPhoneNumberForForgotPass,
} = authSlice.actions;

export default authSlice.reducer;
