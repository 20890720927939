import axios from "axios";

import { destroyToken } from "./Token";

const authOpenUrl = axios.create({
  baseURL: "https://auth.xona.tj/api/v1/",
  withCredentials: false,
});

const searchOpenUrl = axios.create({
  baseURL: "https://search.xona.tj/api/v1/search/",
  withCredentials: false,
});

const authSecureUrl = axios.create({
  baseURL: "https://auth.xona.tj/api/v1/",
});

authSecureUrl.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

authSecureUrl.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

authSecureUrl.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const {
      config,
      response: { status },
    } = error;

    const originalRequest = config;

    if (status === 401) {
      const refreshToken = localStorage.getItem("refresh_token");
      if (!refreshToken) {
        destroyToken();
        return Promise.reject(error);
      }
      try {
        const { data } = await authOpenUrl.get(
          `auth/refresh?refreshToken=${refreshToken}`
        );
        localStorage.setItem("token", data?.data?.accessToken);
        originalRequest.headers.Authorization = `Bearer ${data?.data?.accessToken}`;
        return axios(originalRequest);
      } catch (err) {
        destroyToken();
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export { authOpenUrl, authSecureUrl, searchOpenUrl };
